import { createSlice } from '@reduxjs/toolkit';
import StoreLink from '../../models/StoreLink'
import {
    getStoreLinks,
    createStoreLink,
    removeStoreLink
} from '../services/awsService';
const SLICE_NAME = 'icons';

function sortByPriority(icon1, icon2) {
    const prio1 = Number(icon1.priority);
    const prio2 = Number(icon2.priority);

    if (prio1 > prio2) {
        return -1;
    } else if (prio1 < prio2) {
        return 1;
    }

    return 0;
}

function mergeAndRemoveDuplicates(arr1, arr2) {
    const combinedArray = arr1.concat(arr2);
  
    const uniqueObjects = combinedArray.reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
    }, {});
  
    return Object.values(uniqueObjects);
}

export const storeIconsSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        icons: [],
        lastUpdated: null,
        isLoadingIconGet: null,
        isLoadingIconCreate: null,
        isLoadingIconRemove: null,
        error: null
    },
    reducers: {
        setData: (state, action) => {
            if (action.payload) {
                state.icons = StoreLink.buildStoreIconsFromResponse(action.payload).map(item => item.toObject()).sort(sortByPriority);;
                state.lastUpdated = new Date();
            };
        },
        addLinks: (state, action) => {
            const newIcons = StoreLink.buildStoreIconsFromResponse(action.payload).map(item => item.toObject());
            if (newIcons && newIcons.length) {
                state.icons = mergeAndRemoveDuplicates(state.icons, newIcons).sort(sortByPriority);
                state.lastUpdated = new Date();
            };
        },
        removeLink: (state, action) => {
            state.icons = state.icons.filter(messageData => {
                return messageData.id !== action.payload?.icon?.ID;
            });
        },
        setLoading: (state, action) => {
            state[action.payload.type] = action.payload.value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const fetchStoreLinksAsync = (storeLink) => async (dispatch, getState) => {
    const currentState = getState().storeMessages;

    if (currentState.isLoadingIconGet) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingIconGet',
        value: true
    }));

    try {
        const getStoreIconsResult = await getStoreLinks(storeLink);

        if (getStoreIconsResult.success) {
            dispatch(setData(getStoreIconsResult));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoading({
        type: 'isLoadingIconGet',
        value: false
    }));
};

export const createStoreLinkAsync = (newLinkData) => async (dispatch, getState) => {
    const currentState = getState().storeMessages;

    if (currentState.isLoadingIconCreate) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingIconCreate',
        value: true
    }));    

    try {
        const createLinkResult = await createStoreLink(newLinkData);

        if (createLinkResult.success) {
            dispatch(
                addLinks(createLinkResult)
            );
        }
    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingIconCreate',
        value: false
    }));
};

export const removeStoreLinkAsync = (linkUuid) => async (dispatch, getState) => {
    const currentState = getState().storeMessages;

    if (currentState.isLoadingIconRemove || !linkUuid) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingIconRemove',
        value: true
    }));    

    try {
        const deleteNoteResult = await removeStoreLink(linkUuid);
        if (deleteNoteResult.success) {
            dispatch(
                removeLink(deleteNoteResult)
            );
        }
    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingIconRemove',
        value: false
    }));
};

export const {
    setData,
    addLinks,
    removeLink,
    setLoading,
    setError
} = storeIconsSlice.actions;
export default storeIconsSlice.reducer;
