/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "login",
            "endpoint": "https://sd3m2i1lse.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:670e5b1c-19af-48a1-9640-15266fa07ad4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_G8Jxk0v0b",
    "aws_user_pools_web_client_id": "1lamckmaug9lp434t0vr07l3ne",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "FailedOrderData-main",
            "region": "us-east-1"
        },
        {
            "tableName": "IPRange-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Icons-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Notes-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Stores-main",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "icon-images221124-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
