import { createSlice } from '@reduxjs/toolkit';
import {
    getAllUsers,
    createStoreUser,
    updateStoreUserRole,
    removeStoreUser
} from '../services/awsService';
import StoreUser from '../../models/StoreUser';

const SLICE_NAME = 'storeUsers';

export const storeMessagesSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        allUsers: [],
        lastEvaluatedKey: null,
        isLoadingGetAll: null,
        isLoadingUserCreate: null,
        isLoadingUserRemove: null,
        error: null
    },
    reducers: {
        setData: (state, action) => {
            if (action.payload && action.payload.users) { 
                const users = action.payload.users;
               
                if (action.payload.previousEvaluatedKey) {
                    state.allUsers = state.allUsers.concat(users);
                } else {
                    state.allUsers = users;
                }

                state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
            };
        },
        addUser: (state, action) => {
            state.allUsers = state.allUsers.filter(user => {
                return !(user.fromIP === action.payload.fromIP && user.toIP === action.payload.toIP);
            });
            state.allUsers.push(action.payload);
        },
        removeUser: (state, action) => {
            state.allUsers = state.allUsers.filter(user => {
                return !(user.fromIP === action.payload.fromIP && user.toIP === action.payload.toIP);
            });
        },
        setLoadingGetAll: (state, action) => {
            state.isLoadingGetAll = action.payload;
        },
        setLoadingCreate: (state, action) => {
            state.isLoadingUserCreate = action.payload;
        },
        setLoadingRoleUpdate: (state, action) => {
            state.isLoadingUserRoleUpdate = action.payload;
        },
        setLoadingRemove: (state, action) => {
            state.isLoadingUserRemove = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Admin call to fech all currenst store users
 */
export const fetchAllStoreUsersAsync = (lastLoadedUser) => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState()[SLICE_NAME];

    if (currentState.isLoadingGetAll || !registrationState.isAdmin) {
        return;
    }

    dispatch(setLoadingGetAll(true));    

    try {
        const getAllUsersResult = await getAllUsers(lastLoadedUser);

        if (getAllUsersResult.success) {
            dispatch(setData({
                users: StoreUser.buildStoreUsers(getAllUsersResult.users),
                lastEvaluatedKey: getAllUsersResult.lastEvaluatedKey,
                previousEvaluatedKey: lastLoadedUser
            }));
        }

    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoadingGetAll(false));    
};

export const createStoreUserAsync = (storeUser) => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState()[SLICE_NAME];

    if (!storeUser || !registrationState.isAdmin) {
        return;
    }

    if (Array.isArray(storeUser)) {
        storeUser.forEach(storeUserData => {
            dispatch(createStoreUserAsync(storeUserData));
        });
        return;
    }

    if (currentState.isLoadingUserCreate) {
        setTimeout(() => {
            dispatch(createStoreUserAsync(storeUser));
        }, 100);
        return;
    }

    dispatch(setLoadingCreate(true));    

    try {
        const createUserResult = await createStoreUser(storeUser);

        if (createUserResult.success) {
            console.log('Created user:', storeUser)

            dispatch(addUser(storeUser.toObject()));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoadingCreate(false));    
};

export const updateStoreUserRoleAsync  = (storeUser) => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState()[SLICE_NAME];

    if (!storeUser || !registrationState.isAdmin || currentState.isLoadingUserRoleUpdate) {
        return;
    }

    dispatch(setLoadingRoleUpdate(true));    

    try {
        const updateStoreUserRoleResult = await updateStoreUserRole(storeUser);

        if (updateStoreUserRoleResult.success) {
            console.log('Updated user role:', storeUser)

            dispatch(addUser(storeUser.toObject()));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoadingRoleUpdate(false));    
};

export const removeStoreUserAsync = (storeUser) => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState()[SLICE_NAME];

    if (currentState.isLoadingUserCreate || !storeUser || !registrationState.isAdmin) {
        return;
    }

    dispatch(setLoadingRemove(true));    

    try {
        const removeUserResult = await removeStoreUser(storeUser);

        if (removeUserResult.success) {
            dispatch(removeUser(storeUser));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoadingRemove(false));    
};

export const {
    setData,
    addUser,
    removeUser,
    setLoadingGetAll,
    setLoadingCreate,
    setLoadingRoleUpdate,
    setLoadingRemove,
    setError
} = storeMessagesSlice.actions;

export default storeMessagesSlice.reducer;
