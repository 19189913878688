import { fetchAuthSession } from 'aws-amplify/auth';
import { createSlice } from '@reduxjs/toolkit';
import loginUser from '../../router/AutomaticLoginViaIP';

const SLICE_NAME = 'registration';
let loggingInProgress = false;

export const registrationSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        registered: null,
        missingAccount: false,
        groups: [],
        isStore: null,
        isAdmin: null,
        isLoading: false,
        error: ''
    },
    reducers: {
        resetData: (state) => {
            state.registered = null;
            state.missingAccount = false;
            state.groups = [];
            state.isStore = null;
            state.isAdmin = null;
            state.isLoading = false;
            state.error = '';
        },
        setData: (state, action) => {
            state.registered = action.payload.registered;
            state.groups = action.payload.groups;
            state.isStore = action.payload.isStore;
            state.isAdmin = action.payload.isAdmin;
            state.missingAccount = !!action.payload.missingAccount;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        increaseLoginCount: (state, reset) => {
            if (reset) {
                state.loginCount = 0;
            } else {
                state.loginCount = state.loginCount + 1;
            }
        },
    },
});

export const fetchRegistrationDataAsync = () => async (dispatch, getState) => {
    const currentState = getState()[SLICE_NAME];

    if (currentState.isLoading || currentState.missingAccount || currentState.registered) {
        return;
    }
    dispatch(setLoading(true));

    try {
        const data = await fetchAuthSession({ forceRefresh: true, bypassCache: true });
        const customerGroups = data.tokens.accessToken.payload['cognito:groups'];
        const customerGroup = customerGroups && customerGroups.length ? customerGroups[0] : '';
        console.log('registration', data)

        dispatch(setData({
            registered: true,
            groups: customerGroups,
            isStore: ['stores', 'admins'].includes(customerGroup),
            isAdmin: customerGroups.includes('admins')
        }));
    } catch (error) {
        if (error.$metadata?.httpStatusCode === 400 && !loggingInProgress && !currentState.missingAccount) {
            loggingInProgress = true;
            const loginResult = await loginUser();

            dispatch(setLoading(false));
            loggingInProgress = false;

            if (loginResult.error) {
                dispatch(setData({
                    registered: false,
                    groups: [],
                    isStore: false,
                    isAdmin: false,
                    missingAccount: true
                }));
                dispatch(setError(error.message));
            } else {
                dispatch(fetchRegistrationDataAsync());
            }

            return;
        }

        dispatch(setError(
            error
                ? error.message
                : 'Account Not Found'
            )
        );
        dispatch(setData({
            registered: false,
            groups: [],
            isStore: false,
            isAdmin: false
        }));
    }
    dispatch(setLoading(false));
};

export const { resetData, setData, setLoading, setError } = registrationSlice.actions;
export default registrationSlice.reducer;
