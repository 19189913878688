import Layout from '../components/Layout';

export default function Welcome() {
    return (
        <Layout>
            <h1>Missing Page</h1>
        </Layout>
        
    )
}
