import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AdminWrapper from './AdminControlPanel';

import {
    fetchAllMessagesAsync,
    createStoreMessage,
    removeStoreMessage,
    setTargetStoreData
} from '../../stateManagment/slices/storeMessagesSlice';
class StoreMessages extends React.Component {
    componentDidMount() {
        this.props.fetchAllMessagesAsync();
    }

    generateStoreMessages() {
        if (this.props.storeMessages?.isLoadingAllMessages) {
            return <tr>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
            </tr>;
        }

        return Object.keys(this.props.storeMessages
                ? this.props.storeMessages.allNotes
                : {}
            ).map((storeID, storeIndex) => {
                return this.props.storeMessages.allNotes[storeID].map((messageData, messageIndex) => {
                    return <tr key={`${storeIndex}-${messageIndex}`}>
                        <td>{storeID}</td>
                        <td>{messageData.message}</td>
                        <td><Button
                                variant="danger"
                                onClick={() => this.props.removeStoreMessage(messageData.uuid)}
                                disabled={this.props.storeMessages.isLoadingMessageRemove}
                            >
                                {
                                    !this.props.storeMessages.isLoadingMessageRemove
                                        ? 'REMOVE'
                                        : <Spinner animation="border" size="sm" />
                                }
                            </Button>
                        </td>
                    </tr>
                }) 
        });
    }

    addStoreMessage() {
        this.props.createStoreMessage(this.props.storeMessages?.targetStoreID, this.props.storeMessages?.targetMessage);
    }

    render() {
        return <AdminWrapper>
            Add the message which will be shown to the selected store. In the case you want to have a general
            message to be shown to all the stores, just don't put anything inside the Store ID.
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}>Store ID</th>
                        <th style={{
                            width: '100%'
                        }}>Message</th>
                        <th>Action</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td>
                            {
                                typeof this.props.storeMessages?.createMessage?.storeID === 'string'
                                    ? <Form.Control
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                        size="sm"
                                        id="storeID"
                                        value={this.props.storeMessages.createMessage.storeID}
                                        onChange={e => {
                                            this.props.setTargetStoreData({
                                                storeID: e.target.value
                                            })
                                        }}
                                    /> : null
                            }
                        </td>
                        <td>
                            {
                                typeof this.props.storeMessages?.createMessage?.message === 'string'
                                ? <Form.Control
                                    as="textarea"
                                    rows={3}
                                    id="storeMessage"
                                    value={this.props.storeMessages?.createMessage?.message}
                                    onChange={e => {
                                        this.props.setTargetStoreData({
                                            message: e.target.value
                                        })
                                    }}
                                /> : null
                            }
                        </td>
                        <td>
                            <Button
                                variant="success"
                                onClick={() => this.addStoreMessage()}
                                disabled={this.props.storeMessages.isLoadingMessageCreate}
                            >
                                {
                                    !this.props.storeMessages.isLoadingMessageCreate
                                        ? 'ADD'
                                        : <Spinner animation="border" size="sm" />
                                }
                            </Button>
                        </td>
                    </tr>

                    {
                        this.props.storeMessages?.allNotes && Object.keys(this.props.storeMessages.allNotes)?.length
                            ? this.generateStoreMessages()
                            : null
                    }
                </tbody>
            </Table>

        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    storeMessages: state.storeMessages
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllMessagesAsync: () => dispatch(fetchAllMessagesAsync()),
        createStoreMessage: () => dispatch(createStoreMessage()),
        removeStoreMessage: (storeUuid) => dispatch(removeStoreMessage(storeUuid)),
        setTargetStoreData: (data) => dispatch(setTargetStoreData(data))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(StoreMessages);