import React from 'react';
import Container from 'react-bootstrap/Container';
import './SnipesPage.css';

class SnipesPage extends React.Component {
    constructor(props) {
        super(props);

        this.pageClasses = [
            'snipes-page'
        ];

        if (props.centered) {
            this.pageClasses.push('centered');
        }
    }

    render() {
        return <Container className={this.pageClasses.join(' ')}>
            {this.props.children}
        </Container>
    }
}

export default SnipesPage;
