import { createSlice } from '@reduxjs/toolkit';
import { fetchStoreDataAsync } from './sfccStoreSlice';
import {
    getMessages,
    getAllMessages,
    createStoreMessages,
    deleteStoreMessages
} from '../services/awsService';

const MESSAGE_UPDATE_TIME_HOURS = 0.005;
const SLICE_NAME = 'storeMessages';

function isDateOlderThan(targetTime, hours) {
    if (!targetTime) {
        return true;
    }

    const targetDate = new Date(targetTime);
    const currentDate = new Date();
    const pastDate = new Date(currentDate.getTime() - (hours * 60 * 60 * 1000));

    return targetDate < pastDate;
}

export const storeMessagesSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        notes: [],
        allNotes: {},
        lastUpdated: null,
        isLoadingStoreMessages: null,
        isLoadingAll: null,
        isLoadingMessageCreate: null,
        isLoadingMessageRemove: null,
        createMessage: {
            storeID: '',
            message: ''
        },
        error: null
    },
    reducers: {
        setData: (state, action) => {
            if (action.payload && action.payload.length) {
                state.notes = action.payload.map(messageData => messageData.Message);
            };
        },
        setTargetStoreData: (state, action) => {
            if (!state.createMessage) {
                state.createMessage = {};
            }

            state.createMessage.storeID = typeof action.payload.storeID === 'string'
                ? action.payload.storeID
                : state.createMessage.storeID;
            state.createMessage.message = typeof action.payload.message === 'string'
                ? action.payload.message
                : state.createMessage.message;
        },

        setAllMessages: (state, action) => {
            state.allNotes = {};

            if (action.payload && action.payload.length) {
                action.payload.forEach(messageData => {
                    if (state.allNotes[messageData.StoreID]) {
                        state.allNotes[messageData.StoreID].push({
                            storeID: messageData.StoreID,
                            message: messageData.Message,
                            uuid: messageData.UUID,
                            dateCreated: messageData.DateCreated
                        });
                    } else {
                        state.allNotes[messageData.StoreID] = [{
                            storeID: messageData.StoreID,
                            message: messageData.Message,
                            uuid: messageData.UUID,
                            dateCreated: messageData.DateCreated
                        }];
                    }
                });
            };
        },
        addNote: (state, action) => {
            if (state.allNotes[action.payload.StoreID]) {
                state.allNotes[action.payload.StoreID].push({
                    storeID: action.payload.StoreID,
                    message: action.payload.Message,
                    timeCreated: action.payload.TimeCreated,
                    uuid: action.payload.UUID
                });
            } else {
                state.allNotes[action.payload.StoreID] = [{
                    storeID: action.payload.StoreID,
                    message: action.payload.Message,
                    timeCreated: action.payload.TimeCreated,
                    uuid: action.payload.UUID
                }];
            }

            state.createMessage.message = '';
        },
        removeNote: (state, action) => {
            Object.keys(state.allNotes).forEach(key => {
                state.allNotes[key] = state.allNotes[key].filter(messageData => {
                    return messageData.uuid !== action.payload;
                });

            })
        },
        setLoading: (state, action) => {
            state[action.payload.type] = action.payload.value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const fetchStoreMessagesAsync = () => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState().storeMessages;
    const sfccStoreState = getState().sfccStore;

    if (currentState.isLoadingStoreMessages
        || !registrationState.registered
        || !isDateOlderThan(currentState.lastUpdated, MESSAGE_UPDATE_TIME_HOURS
    )) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingStoreMessages',
        value: true
    }));

    if (!sfccStoreState.value || !sfccStoreState.value.storeID) {
        await fetchStoreDataAsync();
    }

    try {
        const storeID = sfccStoreState.value.storeID;
        const getStoreMessagesResult = await getMessages(storeID);

        if (getStoreMessagesResult.success) {
            dispatch(setData(getStoreMessagesResult.messages));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingStoreMessages',
        value: false
    }));
};

/**
 * Admin call to fech all store messages and stores them into the current state
 */
export const fetchAllMessagesAsync = () => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState().storeMessages;

    if (currentState.isLoadingAllMessages || !registrationState.registered) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingAllMessages',
        value: true
    }));    

    try {
        const getAllMessagesResult = await getAllMessages();

        if (getAllMessagesResult.success) {
            dispatch(setAllMessages(getAllMessagesResult.messages));
        }

    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingAllMessages',
        value: false
    }));
};

export const createStoreMessage = () => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState().storeMessages;

    if (currentState.isLoadingMessageCreate || !currentState.createMessage?.message || !registrationState.registered) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingMessageCreate',
        value: true
    }));    

    try {
        const createNoteResult = await createStoreMessages(
            currentState.createMessage?.storeID,
            currentState.createMessage?.message
        );

        if (createNoteResult.success) {
            dispatch(
                addNote(createNoteResult.message)
            );
        }
    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingMessageCreate',
        value: false
    }));
};

export const removeStoreMessage = (storeUuid) => async (dispatch, getState) => {
    const registrationState = getState().registration;
    const currentState = getState().storeMessages;

    if (currentState.isLoadingMessageRemove || !registrationState.registered) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingMessageRemove',
        value: true
    }));    

    try {
        const deleteNoteResult = await deleteStoreMessages(storeUuid);
        if (deleteNoteResult.success) {
            dispatch(
                removeNote(storeUuid)
            );
        }
    } catch (error) {
        dispatch(setError(error.message));
    }
    dispatch(setLoading({
        type: 'isLoadingMessageRemove',
        value: false
    }));
};

export const {
    setData,
    setTargetStoreData,
    setStoreMessages,
    setAllMessages,
    addNote,
    removeNote,
    setLoading,
    setError
} = storeMessagesSlice.actions;
export default storeMessagesSlice.reducer;
