import React from 'react';

import './HomeIcon.css';

class HomeIcon extends React.Component {
    render() {
        return <div className="icon-container">
            <a href={this.props.data.url.replace('{{storeID}}', this.props.storeID)}  className="title" target="_blank" rel="noreferrer">
                <img src={this.props.data.imageUrl} alt={this.props.data.label} style={{height: '75px', width: '100%'}} className="icon-img"/>
            </a>

            <a href={this.props.data.url.replace('{{storeID}}', this.props.storeID)} className="title" target="_blank" rel="noreferrer">{this.props.data.label}</a>
        </div>
    }
}

export default HomeIcon