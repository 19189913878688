class StoreLink {
    constructor (url, label, imageUrl, priority, imageContent = '', fileName = '', ID = '') {
        this.id = ID;
        this.url = url;
        this.label = label;
        this.imageUrl = imageUrl;
        this.imageContent = imageContent;
        this.priority = priority;
        this.fileName = fileName;
    }

    static buildStoreIconsFromResponse(serviceResponse) {
        if (!serviceResponse || !serviceResponse.icons) {
            return [];
        }

        const icons = Array.isArray(serviceResponse.icons) ? serviceResponse.icons : [ serviceResponse.icons ];
        return icons.map(iconPayload => {
            return new StoreLink(
                iconPayload.URL,
                iconPayload.Label,
                iconPayload.ImageUrl,
                iconPayload.Priority,
                null,
                null,
                iconPayload.ID
            );
        });
    }

    [Symbol.equals](other) {
        return this.ID === other.ID;
    }

    toString() {
        return JSON.stringify({
            id: this.id,
            url: this.url,
            label: this.label,
            imageUrl: this.imageUrl,
            priority: this.priority
        });
    }

    toObject() {
        return {
            id: this.id,
            url: this.url,
            label: this.label,
            imageUrl: this.imageUrl,
            priority: this.priority,
            imageContent: this.imageContent,
            fileName: this.fileName
        };
    }
}

export default StoreLink;
