import SnipesButton from '../components/SnipesButton';
import Layout from '../components/Layout';
import SnipesPage from '../components/SnipesPage';
import { ROUTING } from '../router/router';

const h1Style = {
    fontSize: '55px',
    fontWeight: '800',
    textAlign: 'center',
    paddingBottom: '25px'
};

export default function Welcome() {
    return (            
        <Layout>
            <SnipesPage centered={true}>
                <h1 style={h1Style}>WELCOME TO SNIPES NET</h1>
                <SnipesButton content="CONTINUE" url={ROUTING.MAP.url}></SnipesButton>
            </SnipesPage>
        </Layout>
    )
}
