import React from 'react';
import { Navigate } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';

import { ROUTING } from '../../router/router';
import Loading from '../Loading';
import { fetchRegistrationDataAsync } from '../../stateManagment/slices/registrationSlice';

const PROTECTION_LEVEL = {
    NONE: 0,
    STORE: 1,
    ADMIN: 2
}
class ProtectedRoute extends React.Component {
    componentDidMount() {
        this.props.fetchRegistrationDataAsync();
    }

    protectRoute() {
        const { registration } = this.props;

        if (registration.isLoading || registration.isStore === null || registration.isAdmin === null) {
            return <Loading/>;
        } 

        if (registration.isAdmin) {
            return <Container>
                {this.props.children}
            </Container>
        }
        if (PROTECTION_LEVEL.STORE === this.props.protection && registration.isStore) {
            return <Container>
                {this.props.children}
            </Container>
        }

        return <Navigate to={ROUTING.STORE_NO_ACCESS.url}/>
    }

    render() {
        const { registration } = this.props;

        if (registration.isLoading) {
            return <Loading/>;
        }

        if (!this.props.protection || this.props.protection === PROTECTION_LEVEL.NONE) {
            return <Container>
                {this.props.children}
            </Container>
        }

        return this.protectRoute();
    }
    
}

const mapStateToProps = (state) => ({
    registration: state.registration
});
  
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        fetchRegistrationDataAsync
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);

export {
    PROTECTION_LEVEL
}