function ipStringToLong(ipString) {
    var octets = ipString.split('.');
    if (octets.length !== 4) {
        throw new Error("Invalid format -- expecting a.b.c.d");
    }
    var ip = 0;
    for (var i = 0; i < octets.length; ++i) {
        var octet = parseInt(octets[i], 10);
        if (Number.isNaN(octet) || octet < 0 || octet > 255) {
            throw new Error("Each octet must be between 0 and 255");
        }
        ip += octet << ((octets.length - 1 - i) * 8); // Corrected bitwise operation
    }
    return ip >>> 0; // Unsigned right shift to ensure positive value
}

function longToIpString(ipLong) {
    if (ipLong < 0 || ipLong > 4294967295) {
        throw new Error("IP address out of range");
    }

    return [
        (ipLong >>> 24) & 0xFF,
        (ipLong >>> 16) & 0xFF,
        (ipLong >>> 8) & 0xFF,
        ipLong & 0xFF
    ].join('.');
}

class StoreUser {
    constructor (storeID, fromIPFormated, toIPFormated = '', role = 'stores', isActive = false) {
        this.storeID = storeID;
        this.fromIPFormated = typeof fromIPFormated === 'string' ? fromIPFormated : longToIpString(fromIPFormated);
        this.fromIP = ipStringToLong(this.fromIPFormated);
        if (toIPFormated) {
            this.toIPFormated = typeof toIPFormated === 'string' ? toIPFormated : longToIpString(toIPFormated);
            this.toIP = ipStringToLong(this.toIPFormated);
        } else {
            this.toIP = this.fromIP + 1;
            this.toIPFormated = longToIpString(this.toIP);
        }
        this.role = role;
        this.isActive = isActive;
    }

    static buildStoreUsers(storeUsersArray) {
        if (!storeUsersArray || !storeUsersArray.length) {
            return [];
        }

        const users = storeUsersArray.map(storeUserDate => new StoreUser(
            storeUserDate.StoreID,
            storeUserDate.IPStart,
            storeUserDate.IPEnd,
            storeUserDate.Role,
            storeUserDate.IsActive
        ).toObject());

        return users;
    }

    static buildStoreClassInstance(storeData) {
        return new StoreUser(
            storeData.storeID,
            storeData.fromIPFormated,
            storeData.toIPFormated,
            storeData.role,
            storeData.isActive
        )
    }

    changeRole(newRole) {
        this.role = newRole;
    }

    [Symbol.equals](other) {
        return this.storeID === other.storeID && this.fromIP === other.fromIP && this.toIP === other.toIP;
    }

    toString() {
        return JSON.stringify({
            storeID: this.storeID,
            fromIPFormated: this.fromIPFormated,
            fromIP: this.fromIP,
            toIPFormated: this.toIPFormated,
            toIP: this.toIP,
            role: this.role,
            isActive: this.isActive
        });
    }

    toObject() {
        return {
            storeID: this.storeID,
            fromIPFormated: this.fromIPFormated,
            fromIP: this.fromIP,
            toIPFormated: this.toIPFormated,
            toIP: this.toIP,
            role: this.role,
            isActive: this.isActive
        };
    }
}

export default StoreUser;
