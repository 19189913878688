import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AdminWrapper from './AdminControlPanel';
import StoreUser from '../../models/StoreUser';

import {
    fetchAllStoreUsersAsync,
    createStoreUserAsync,
    updateStoreUserRoleAsync,
    removeStoreUserAsync
} from '../../stateManagment/slices/storeUsersSlice';

class IPStoreAccess extends React.Component {
    STORE_IP_REGEX = '^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$';
    STORE_ID_PATERN = '^[\\w]+$';
    ROLES = [
        'stores',
        'admins'
    ]

    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();

        this.state = {
            newUserStoreID: '',
            newUserFromIP: '',
            newUserToIP: '',
            fileContent: '',
            role: 'stores',
            lastLoadedElement: '',
            invalidFileError: false,
            validated: false
        }
    }

    updateRole(store, newRole) {
        if (store.role !== newRole) {
            console.log(store, newRole);
            let storeInstance = StoreUser.buildStoreClassInstance(store)
            storeInstance.changeRole(newRole);
            this.props.updateStoreUserRole(storeInstance);
        }
    }

    processStores(content) {
        const storeList = content.split(/\r?\n/);
        this.props.createStoreUser(storeList.map(storeData => {
            const [storeID, storeIP] = storeData.split(';');

            return new StoreUser(
                storeID,
                storeIP
            );
        }));
    }

    validateFile = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const content = e.target.result;
            const storeList = content.split(/\r?\n/);
            const storeIPReg = new RegExp(this.STORE_IP_REGEX);
            const storeIDReg = new RegExp(this.STORE_ID_PATERN);
            let fileValid = false;

            if (storeList && storeList.length) {
                fileValid = !storeList.some(storeData => {
                    const [storeID, storeIP] = storeData.split(';');

                    if (!storeIDReg.test(storeID)) {
                        return true;
                    }
                    if (!storeIPReg.test(storeIP)) {
                        return true;
                    }

                    return false;
                })
            }

            if (fileValid) {
                this.processStores(content);
                this.setState({ fileContent: content });
            } else {
                this.setState({ invalidFileError: true });
            }

            this.fileInputRef.current.value = '';
        };
    
        reader.readAsText(file);
    };

    generateStoreUsers() {
        if ((!this.props.storeUsers?.allUsers || !this.props.storeUsers?.allUsers.length)
            && this.props.storeUsers?.isLoadingGetAll
        ) {
            return <tr>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
                <td><Spinner animation="border" size="sm" /></td>
            </tr>;
        }

        const storeUsers = (this.props.storeUsers?.allUsers || []).map((userData, userIndex) => {
            return  <tr key={userIndex}>
                <td>{userIndex + 1}</td>
                <td>{userData.storeID}</td>
                <td>{userData.fromIPFormated}</td>
                <td>{userData.toIPFormated}</td>
                <td>
                    <Form.Control
                        as="select"
                        aria-label="Small"
                        size="md"
                        value={userData.role}
                        required
                        disabled={!userData.isActive}
                        onChange={e => {
                            this.updateRole(userData, e.target.value)
                        }}
                    >
                        {
                            this.ROLES.map((value, index) => {
                                return (<option value={value} key={index}>{value}</option>)
                            })
                        }
                    </Form.Control>
                </td>
                <td>{!!userData.isActive ? 'YES' : 'NO'}</td>
                <td><Button
                        variant="danger"
                        onClick={() => this.props.removeStoreUser(userData)}
                        disabled={this.props.storeUsers.isLoadingUserRemove}
                    >
                        {
                            !this.props.storeUsers.isLoadingUserRemove
                                ? 'REMOVE'
                                : <Spinner animation="border" size="sm" />
                        }
                    </Button>
                </td>
            </tr>
        });

        return storeUsers;
    }

    createStoreUser(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            validated: true
        });

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        this.props.createStoreUser(new StoreUser(
            this.state.newUserStoreID,
            this.state.newUserFromIP,
            this.state.newUserToIP
        ));

        this.setState({
            newUserStoreID: '',
            newUserFromIP: '',
            newUserToIP: '',
            validated: false
        })
    }

    loadData(useKey) {
        this.props.fetchAllStoreUsersAsync(
            useKey
                ? this.props.storeUsers?.lastEvaluatedKey
                : null
        );
    }

    render() {
        return <AdminWrapper>
            Add new stores here. Make sure to put a valid IP address in the IP range. If you want to add a single IP address,
            add it to the From IP, and for the To IP just increase the last digit. So for instance: 1.1.1.1 - 1.1.1.2
            <br></br>
            <p className="pt-2">You can also upload store file in the format: storeID;StartIpAddress</p>
            <input
                className="pb-2"
                type="file"
                onChange={this.validateFile}
                ref={this.fileInputRef}
                disabled={this.props.storeUsers?.isLoadingUserCreate}
            />
            {
                this.props.storeUsers?.isLoadingUserCreate
                    ? <Spinner animation="border" size="sm"/>
                    : null
            }

            {this.state.invalidFileError ? <p>File validation failed. Fix it and try again.</p> : ''}

            <Button
                variant="success"
                onClick={() => this.loadData()}
                disabled={this.props.storeUsers?.isLoadingGetAll}
            >
                {
                    !this.props.storeUsers?.isLoadingGetAll
                        ? 'RELOAD DATA'
                        : <Spinner animation="border" size="sm" />
                }
            </Button>
            <Form noValidate validated={this.state.validated} onSubmit={(event) => this.createStoreUser(event)}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}>Store ID</th>
                            <th>From IP</th>
                            <th>To IP</th>
                            <th style={{width: '250px'}}>Role</th>
                            <th>Active</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr>
                            <td>
                                
                            </td>
                            <td>
                                <Form.Group controlId="storeID">
                                    <Form.Control
                                        aria-label="Small"
                                        size="sm"
                                        value={this.state.newUserStoreID}
                                        required
                                        pattern={this.STORE_ID_PATERN}
                                        onChange={e => {
                                            this.setState({
                                                newUserStoreID: e.target.value
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid store ID.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group controlId="fromIP">
                                    <Form.Control
                                        aria-label="Small"
                                        size="sm"
                                        required
                                        pattern={this.STORE_IP_REGEX}
                                        value={this.state.newUserFromIP}
                                        onChange={e => {
                                            this.setState({
                                                newUserFromIP: e.target.value
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid IP address.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group controlId="toIP">
                                    <Form.Control
                                        aria-label="Small"
                                        size="sm"
                                        required
                                        pattern={this.STORE_IP_REGEX}
                                        value={this.state.newUserToIP}
                                        onChange={e => {
                                            this.setState({
                                                newUserToIP: e.target.value
                                            })
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid IP address.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </td>
                            <td>
                                Store
                            </td>
                            <td>
                                
                            </td>
                            <td>
                                <Button
                                    variant="success"
                                    type="submit"
                                    disabled={this.props.storeUsers?.isLoadingUserCreate}
                                >
                                    {
                                        !this.props.storeUsers?.isLoadingUserCreate
                                            ? 'ADD'
                                            : <Spinner animation="border" size="sm" />
                                    }
                                </Button>
                            </td>
                        </tr>
                        {
                            this.props.storeUsers?.allUsers && this.props.storeUsers?.allUsers?.length
                                ? this.generateStoreUsers()
                                : null
                        }
                    </tbody>
                </Table>
                {
                    this.props.storeUsers?.allUsers.length > 0 && !this.props.storeUsers?.lastEvaluatedKey
                        ? null
                        : <Button
                            variant="success"
                            onClick={() => this.loadData(true)}
                            disabled={this.props.storeUsers?.isLoadingGetAll}
                        >
                            {
                                !this.props.storeUsers?.isLoadingGetAll
                                    ? 'LOAD MORE'
                                    : <Spinner animation="border" size="sm" />
                            }
                        </Button>
                }
            </Form>

        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    storeUsers: state.storeUsers
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllStoreUsersAsync: (lastLoadedElement) => dispatch(fetchAllStoreUsersAsync(lastLoadedElement)),
        createStoreUser: (userData) => dispatch(createStoreUserAsync(userData)),
        updateStoreUserRole: (userData) => dispatch(updateStoreUserRoleAsync(userData)),
        removeStoreUser: (userData) => dispatch(removeStoreUserAsync(userData))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(IPStoreAccess);