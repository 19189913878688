import React from 'react';
import Button from 'react-bootstrap/Button';

const buttonStyle = {
    borderRadius: '30px',
    minWidth: '150px',
    backgroundColor: 'black'
}

class SnipesButton extends React.Component {
    render() {
        return <Button style={buttonStyle} href={this.props.url}>
            {this.props.content}
        </Button>
    }
}

export default SnipesButton;
