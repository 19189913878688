import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ROUTING } from '../router/router';
import { fetchStoreDataAsync } from '../stateManagment/slices/sfccStoreSlice';
import { fetchRegistrationDataAsync } from '../stateManagment/slices/registrationSlice';
import DigitalClock from './DigitalClock';

class Header extends React.Component {
    componentDidMount() {
        this.props.fetchStoreDataAsync();
        this.props.fetchRegistrationDataAsync();
    }

    render () {
        const { sfccStore, registration } = this.props;

        return (
            <Navbar expand="lg" variant="dark" className="bg-body-white" fixed="top" bg="snipes-header">
                <Container>
                    <Navbar.Brand href={ROUTING.WELCOME.url}>
                        <img
                            src="/images/snipes-logo-light.png"
                            width="100"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    
                    <Navbar.Brand>
                        <DigitalClock></DigitalClock>
                    </Navbar.Brand>
                    <Navbar.Brand>
                        <h4>{sfccStore && sfccStore.isLoading ? 'Loading' : sfccStore.value.storeID}</h4>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        {registration?.isAdmin
                            ? <Nav.Link
                                href={ROUTING.ADMIN.url}
                                aria-selected={ROUTING.ADMIN.selected}>
                                    {ROUTING.ADMIN.label}
                            </Nav.Link>
                            : null
                        }
                        <Nav.Link
                            href={ROUTING.MAP.url}
                            aria-selected={ROUTING.MAP.selected}>
                                {ROUTING.MAP.label}
                        </Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    sfccStore: state.sfccStore,
    registration: state.registration
});
  
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        fetchStoreDataAsync,
        fetchRegistrationDataAsync
    },
    dispatch
);
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);