import Layout from '../components/Layout';
import SnipesPage from '../components/SnipesPage';

const h1Style = {
    fontSize: '55px',
    fontWeight: '800',
    textAlign: 'center',
    paddingBottom: '25px'
};

export default function Welcome() {
    return (            
        <Layout>
            <SnipesPage centered={true}>
                <h1 style={h1Style}>No Account Found</h1>
            </SnipesPage>
        </Layout>
    )
}
