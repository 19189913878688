import {
    RouterProvider,
    createBrowserRouter
} from 'react-router-dom';
import { ROUTING } from './router/router';
import Loading from './components/Loading';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const routeData = Object.values(ROUTING).map(route => route.generateRouteElement());
const router = createBrowserRouter(routeData);

export default function App() {
    return <RouterProvider router={router} fallbackElement={<Loading/>} />;
}

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}

