import './Loading.css';
import Layout from './Layout'
import Spinner from 'react-bootstrap/Spinner';

export default function Loading() {
    return (
        <Layout>
            <div className="loader">
                <Spinner animation="grow" />
            </div>
        </Layout>
    )
};
