import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './stateManagment/store';
import { PersistGate } from 'redux-persist/integration/react';

Amplify.configure(Object.assign(
    config,
    {
        Auth: {
            region: config.aws_cognito_region,
            userPoolId: config.aws_user_pools_id,
            userPoolWebClientId: config.aws_user_pools_web_client_id
        },
        API: {
            endpoints: [
                {
                    name: config.aws_cloud_logic_custom.name,
                    endpoint: config.aws_cloud_logic_custom.endpoint,
                    custom_header: async () => { 
                    // Retrieve the JWT token from the current authenticated session
                    return { Authorization: `Bearer ${'asdasd'}` };
                    }
                }
            ]
        }
    }
));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
