import {
    CognitoUserPool,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoUser,
    CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import config from '../aws-exports';

export default function loginUser() {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        const targetRoute = (config.aws_cloud_logic_custom || []).find(data => data.name === 'login').endpoint + '/login';

        if (!targetRoute) {
            console.log('Missing login route', config.aws_cloud_logic_custom.find(data => data.name === 'login'));
            return;
        }

        xhr.open('GET', targetRoute);
        xhr.onload = function() {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);

                const userPool = new CognitoUserPool({
                    UserPoolId: config.aws_user_pools_id,
                    ClientId: config.aws_user_pools_web_client_id,
                });
            
                const cognitoIdToken = new CognitoIdToken({
                    IdToken: response.idToken,
                });
            
                const cognitoAccessToken = new CognitoAccessToken({
                    AccessToken: response.accessToken,
                });
            
                const cognitoRefreshToken = new CognitoRefreshToken({
                    RefreshToken: response.refreshToken,
                });
            
                const user = new CognitoUser({
                    Username: response.username,
                    Pool: userPool,
                });
            
                user.setSignInUserSession(new CognitoUserSession({
                    AccessToken: cognitoAccessToken,
                    IdToken: cognitoIdToken,
                    RefreshToken: cognitoRefreshToken,
                }));

                console.log('Logged in as:', response.username);
                resolve({
                    error: false
                });
            } else {
                console.log('User not found');
                resolve({
                    error: true,
                    errorMessage: 'User not found'
                });
            }
        };
        xhr.send();
    });
}
