import Layout from '../components/Layout';
import { withAuthenticator } from '@aws-amplify/ui-react';

function Login() {      
    return (
        <Layout>
            Test
        </Layout>
    )
}

export default withAuthenticator(Login);