import React from 'react';
import HomeIcon from '../components/welcome/HomeIcon';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import { connect } from 'react-redux';
import { fetchStoreDataAsync } from '../stateManagment/slices/sfccStoreSlice';
import { fetchStoreLinksAsync } from '../stateManagment/slices/iconsSlice';
import { Col } from 'react-bootstrap';

const customStyle = {
    fontFamily: 'Tahoma',
    fontSize: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
    margin: '0'
};

class Map extends React.Component {
    getIconData() {
        this.props.fetchStoreLinksAsync();
    }

    componentDidMount() {
        const { sfccStore, fetchStoreDataAsync } = this.props;

        if (!sfccStore?.value?.storeID) {
            fetchStoreDataAsync();
        }

        this.getIconData();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
    render() {
        const { sfccStore } = this.props;

        return (
            !sfccStore.value.storeID
                ? <Loading/>
                : <Layout>
                <Col>
                    <Row xs={2} md={5} lg={5} className="g-4" style={customStyle}>
                        {this.props.icons ? this.props.icons.icons.map((icon, i) => (
                            <HomeIcon key={i} data={icon} storeID={sfccStore.value.storeID}/>
                        )) : null}
                    </Row>
                </Col>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    sfccStore: state.sfccStore,
    icons: state.icons
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchStoreDataAsync: () => dispatch(fetchStoreDataAsync()),
        fetchStoreLinksAsync: () => dispatch(fetchStoreLinksAsync())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Map);