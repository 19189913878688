import React from 'react';

const clockFontStyle = {
    display: 'block',
    color: 'orange',
    textAlign: 'center'
};

class DigitalClock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            time: '',
            date: ''
        };
    }

    getFormatedTime() {
        const date = new Date();
        const day = date.getDate(); // Get the day of the month (1-31)
        const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to make it (1-12)
        const year = date.getFullYear(); // Get the full year (e.g., 2022)

        const formattedDate = `${month}/${day}/${year}`;
        const formatedTime = date.toLocaleTimeString(
            [], {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            }
        );

        return {
            time: formatedTime,
            date: formattedDate
        }
    }

    updateTime() {
        const formatedData = this.getFormatedTime();
        this.setState({
            time: formatedData.time,
            date: formatedData.date
        });
    }

    componentDidMount() {
        this.updateTime()
        setInterval(() => {
            const formatedData = this.getFormatedTime();

            this.setState({
                time: formatedData.time,
                date: formatedData.date
            });
        }, 900)
    }

    render() {
        return <div>
            <span style={clockFontStyle}>{this.state.date},</span>
            <span style={clockFontStyle}>{this.state.time}</span>
        </div>
    }
}

export default DigitalClock;
