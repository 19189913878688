import React from 'react';
import Layout from '../../components/Layout';
import './AdminWelcome.css'; 
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { connect } from 'react-redux';
import { fetchStoreDataAsync } from '../../stateManagment/slices/sfccStoreSlice';
import { ROUTING } from '../../router/router';

class AdminWelcome extends React.Component {
    componentDidMount() {
        const { sfccStore, fetchStoreDataAsync } = this.props;

        if (!sfccStore?.value?.storeID) {
            fetchStoreDataAsync();
        }
    }

    getTabs() {
        const tabs = [
            {
                name: 'Edit Store Messages',
                route: ROUTING.ADMIN_MESSAGES
            },
            {
                name: 'Edit Store Access',
                route: ROUTING.ADMIN_IP_ACCESS
            },
            {
                name: 'Edit Icons',
                route: ROUTING.ADMIN_ICONS
            },
            {
                name: 'Order/Cart Error Reporting',
                route: ROUTING.ADMIN_ORDER_ERROR_REPORTING
            }
        ];

        tabs.forEach(tab => {
            tab.isSelected = tab.route.selected;
        });

        return tabs;
    }

    render() {
        return <Layout>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3} style={{backgroundColor: 'dimgray'}}>
                        <Nav variant="pills" className="flex-column admin-panel-nav">
                            {this.getTabs().map((tab, index) =>
                                <Nav.Item key={index}>
                                    <Nav.Link
                                        eventKey={tab.route.url}
                                        href={tab.route.url}
                                        active={tab.isSelected}>
                                            {tab.name}
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Col>
                    <Col sm={9} className="admin-panel-content">
                        <main>{this.props.children}</main>
                    </Col>
                </Row>
            </Tab.Container>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    sfccStore: state.sfccStore
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchStoreDataAsync: () => dispatch(fetchStoreDataAsync())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminWelcome);