import { fetchAuthSession } from 'aws-amplify/auth';
import { APIGatewayClient, GetResourcesCommand } from '@aws-sdk/client-api-gateway';
import { get, post, del, put, patch} from '@aws-amplify/api';
import config from '../../aws-exports';

const REST_API_REGEX = /https:\/\/([^.]+)\.execute-api\..+/;
const PROXY = '{proxy+}';

const AWS_API_ROUTES = {
    MESSAGES: '/messages',
    MESSAGES_PROXY: `/messages/${PROXY}`,
    LOGIN: '/login',
    LOGIN_PROXY: `/login/${PROXY}`,
    ROOT: '/',
    USERS: '/users',
    USERS_PROXY: `/messages/${PROXY}`,
    ICONS: '/links',
    ICONS_PROXY: `/links/${PROXY}`,
    ORDER_REPORTING: `/order-reporting`,
    ORDER_REPORTING_PROXY: `/order-reporting/${PROXY}`
};

/**
 * Converts the stream to a string
 * @param {any} stream - target stread
 * @returns {string} converted string
 */
async function streamToString(stream) {
    const reader = stream.getReader();
    const decoder = new TextDecoder();
    let result = '';
    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }
    return result;
}

/**
 * Sends an authenticated API call to API gateaway
 * @param {string} method - target API method (POST, GET...)
 * @param {string} apiPath - path after the API gateaway original path
 * @param {any|null} queryParams - query params 
 * @param {any|null} bodyParams - body for the post request
 * @returns 
 */
async function makeAuthenticatedAPICall(method, apiPath, queryParams, bodyParams) {
    const apiName = config.aws_cloud_logic_custom[0].name;

    let apiFunction;

    switch (method) {
        case 'GET':
            apiFunction = get;
            break;
        case 'POST':
            apiFunction = post;
            break;
        case 'DELETE':
            apiFunction = del;
            break;
        case 'PUT':
            apiFunction = put;
            break;
        case 'PATCH':
            apiFunction = patch;
            break;
        default:
            break;
    }

    const configuration = {
        apiName: apiName,
        path: apiPath,
        queryStringParameters: queryParams || {},
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        options: {
            headers: { 'Content-Type': 'application/json' },
            queryParams: queryParams || {},
        }
    }

    if (bodyParams) {
        configuration.options.body = bodyParams;
    }

    const response = await apiFunction(configuration).response;
    const responseString = await streamToString(response.body);
    return JSON.parse(responseString);
}

function getRestApiID() {
    const baseUrl = config.aws_cloud_logic_custom[0].endpoint;
    const match = baseUrl.match(REST_API_REGEX);

    return match && match.length > 1 ? match[1] : '';
}

async function buildAwsClient(httpMethod, pathWithQueryString, credentials) {
    let data = credentials;

    if (!credentials) {
        data = await fetchAuthSession();

    }
    const configClient = {
        credentials: {
            accessKeyId: data.credentials.accessKeyId,
            secretAccessKey: data.credentials.secretAccessKey,
            sessionToken: data.credentials.sessionToken,
        },
        region: config.aws_cognito_region
    }

    if (httpMethod) {
        configClient.httpMethod = httpMethod
    }

    if (pathWithQueryString) {
        configClient.pathWithQueryString = pathWithQueryString
    }

    return new APIGatewayClient(configClient);
}

async function getApiResources(credentials) {
    const client = await buildAwsClient(null, null, credentials)
    const params = {
        restApiId: getRestApiID()
    };
      
    const command = new GetResourcesCommand(params);
    return await client.send(command);
}

async function getRouteID(routePath) {
    const routeData = await getApiResources();

    if (routeData.items) {
        return routeData.items.find((item) => routePath === item.path).id;
    }
}

async function getMessages(storeID) {
    if (!storeID) {
        return [];
    }

    return await makeAuthenticatedAPICall(
        'GET',
        AWS_API_ROUTES.MESSAGES_PROXY.replace(PROXY, storeID)
    );
}

async function getAllMessages() {
    return await makeAuthenticatedAPICall(
        'GET',
        AWS_API_ROUTES.MESSAGES
    );
}

async function createStoreMessages(storeID, message) {
    return await makeAuthenticatedAPICall(
        'POST',
        AWS_API_ROUTES.MESSAGES,
        {},
        {
            storeID,
            message
        }
    );
}

async function deleteStoreMessages(storeID, message) {
    return await makeAuthenticatedAPICall(
        'DELETE',
        AWS_API_ROUTES.MESSAGES_PROXY.replace(PROXY, storeID)
    );
}

async function getAllUsers(storeUser) {
    return await makeAuthenticatedAPICall(
        'GET',
        AWS_API_ROUTES.USERS,
        storeUser ? {
            from: storeUser.IPStart,
            to: storeUser.IPEnd
        } : null
    );
}

async function createStoreUser(storeUser) {
    return await makeAuthenticatedAPICall(
        'POST',
        AWS_API_ROUTES.USERS,
        {},
        storeUser.toObject()
    );
}

async function updateStoreUserRole(storeUser) {
    return await makeAuthenticatedAPICall(
        'PATCH',
        AWS_API_ROUTES.USERS,
        {},
        storeUser.toObject()
    );
}

async function removeStoreUser(storeUser) {
    return await makeAuthenticatedAPICall(
        'DELETE',
        AWS_API_ROUTES.USERS,
        {
            fromIP: storeUser.fromIP,
            toIP: storeUser.toIP
        }
    );
}

async function getStoreLinks(storeLink) {
    return await makeAuthenticatedAPICall(
        'GET',
        storeLink
            ? AWS_API_ROUTES.ICONS_PROXY.replace(PROXY, storeLink.id)
            : AWS_API_ROUTES.ICONS
    );
}

async function createStoreLink(storeLink) {
    return await makeAuthenticatedAPICall(
        'POST',
        storeLink && storeLink.id ? AWS_API_ROUTES.ICONS_PROXY.replace(PROXY, storeLink.id) : AWS_API_ROUTES.ICONS,
        {},
        storeLink.toObject()
    );
}

async function removeStoreLink(linkUuid) {
    return await makeAuthenticatedAPICall(
        'DELETE',
        AWS_API_ROUTES.ICONS_PROXY.replace(PROXY, linkUuid)
    );
}

async function getOrderReportingFiles() {
    return await makeAuthenticatedAPICall(
        'GET',
        AWS_API_ROUTES.ORDER_REPORTING
    );
}

export {
    AWS_API_ROUTES,
    getApiResources,
    getRouteID,
    getMessages,
    getAllMessages,
    createStoreMessages,
    deleteStoreMessages,
    getAllUsers,
    createStoreUser,
    updateStoreUserRole,
    removeStoreUser,
    getStoreLinks,
    createStoreLink,
    removeStoreLink,
    getOrderReportingFiles
};
