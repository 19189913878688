import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchStoreMessagesAsync } from '../stateManagment/slices/storeMessagesSlice';
import Alert from 'react-bootstrap/Alert';

class Messages extends React.Component {
    lastMessageCount;

    constructor(props) {
        super(props);

        this.state = {
            messageCount: 0
        }
    }

    componentDidMount() {
        this.props.fetchStoreMessagesAsync();
    }

    updateCount() {
        if (this.state.messageCount !== this.lastMessageCount) {
            this.lastMessageCount = this.state.messageCount;

            setTimeout(() => {
                this.setState({messageCount: this.state.messageCount + 1})
            }, 5000);
        }
    }
    generateMessage() {
        let count = this.state.messageCount;
        let messages = this.props.storeMessages?.notes || [];

        if (count && messages.length <= count) {
            this.setState({messageCount: 0});
            return null;
        }

        if (typeof count === 'number' && messages[count]) {
            this.updateCount();
            return messages[count]
                ? <Alert
                    variant="light"
                    transition={true}
                    style={{backgroundColor: 'darkorange', color: 'white'}}>
                        <p>{messages[count]}</p>
                </Alert>
                : null;
        }
        
        return null;
    }

    render () {
        return this.generateMessage();
    }
}

const mapStateToProps = (state) => ({
    storeMessages: state.storeMessages
});
  
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        fetchStoreMessagesAsync
    },
    dispatch
);
  
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
