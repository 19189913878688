import { createSlice } from '@reduxjs/toolkit';
import {
    getOrderReportingFiles
} from '../services/awsService';
const SLICE_NAME = 'orderReporting';

export const storeIconsSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        files: [],
        isLoadingFiles: null,
        error: null
    },
    reducers: {
        setData: (state, action) => {
            if (action.payload) {
                state.files = action.payload.sort((a, b) => {
                    return (Number(b.year * 1000) + Number(b.month * 1000)) - (Number(a.year * 1000) + Number(a.month * 1000));
                });
            };
        },
        setLoading: (state, action) => {
            state[action.payload.type] = action.payload.value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const fetchOrderReportingFilesAsync = () => async (dispatch, getState) => {
    const currentState = getState().orderReporting;

    if (currentState.isLoadingFiles) {
        return;
    }

    dispatch(setLoading({
        type: 'isLoadingFiles',
        value: true
    }));

    try {
        const orderReportingFilesResult = await getOrderReportingFiles();

        if (orderReportingFilesResult.success) {
            dispatch(setData(orderReportingFilesResult.data));
        }
    } catch (error) {
        dispatch(setError(error.message));
    }

    dispatch(setLoading({
        type: 'isLoadingFiles',
        value: false
    }));
};

export const {
    setData,
    addLinks,
    removeLink,
    setLoading,
    setError
} = storeIconsSlice.actions;
export default storeIconsSlice.reducer;
