import React from 'react'
import ProtectedRoute from '../components/auth/ProtectedRoute';

class RouteModel {
    GENERAL_LOADING_MESSAGE = 'Loading Data!';

    constructor (url, component, loadingMessage, label, protection) {
        this.url = url;
        this.component = component;
        this.loadingMessage = loadingMessage;
        this.label = label;
        this.protection = protection;
        this.selected = window.location.pathname === this.url;
    }

    generateRouteElement() {
        return {
            path: this.url,
            loader: () => ({message: this.loadingMessage || this.GENERAL_LOADING_MESSAGE }),
            element: (
                <ProtectedRoute protection={this.protection}>
                    {this.component}
                </ProtectedRoute>
              )
        }
    }
}

export default RouteModel;
