import React from 'react';
import { connect } from 'react-redux';
import AdminWrapper from './AdminControlPanel';
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css"
import './OrderErrorReporting.css'; 

import {
    fetchOrderReportingFilesAsync
} from '../../stateManagment/slices/orderReportingSlice';


class OrderErrorReporting extends React.Component {
    legend = {
        'NO INVENTORY': {
            color: 'forestgreen',
            description: 'Aptos inventory for this product was 0 when placing the order, but SFCC had no inventory.'
        },
        'FORTER': {
            color: 'royalblue',
            description: 'Forter did a fraud check and blocked the order creation.'
        },
        'CC DECLINED': {
            color: 'turquoise',
            description: 'Customers card has been declined for any card related reason.'
        },
        'MISSING CUSTOMER DATA': {
            color: 'midnightblue',
            description: 'Customers order is missing some of the mandatory data like: email, shipping address details, billing address details.'
        },
        'PAYMENT ISSUE': {
            color: 'tomato',
            description: 'Payment related issue to the customers payment method.'
        },
        'PRE AUTH BYPASS': {
            color: 'lime',
            description: 'Somebody directy trying to place the order without going trough the proper SFCC checkout. Most probably with a script.'
        },
        'VALIDATION ERROR': {
            color: 'gold',
            description: 'A pretty general order validation issue. It can be: product validation related (invalid product data, going over max allowed limit for this item per order, trying to buy a released item that is currently not available, hyperelease related issue), validates if coupons are valid (if there are any), validates if order shipments are valid with proper addresses.'
        },
        'HYPE RELEASE LIMIT REACHED': {
            color: 'teal',
            description: 'Exceeding the max bulk buyer limit on regular products using them or on the hype products.'
        },
        'MAX ORDER LIMIT REACHED': {
            color: 'cornflowerblue',
            description: 'Trying to purchase more variants then it is allowed in the current cart.'
        },
        'QUEUEIT FAIL': {
            color: 'slateblue',
            description: 'Queue token validation fail.'
        },
        'INVALID PHONE': {
            color: 'red',
            description: 'Phone related issue.'
        },
        'PLACE ORDER ERROR': {
            color: 'darksalmon',
            description: 'Error placing the order in SFCC.'
        },
        'INVALID ADDRESS': {
            color: 'seagreen',
            description: 'Apple pay related invalid address issue.'
        },
        'MISSING STORE': {
            color: 'gainsboro',
            description: 'Ship to store or BOPIS order without a valid store number saved on the basket/order.'
        },
        'OCAPI ERROR': {
            color: 'goldenrod',
            description: 'Issue reseting the SFCC inventory to 0 in the case customer tries to purchase something that has inventory on SFCC but have no inventory on APTOS.'
        },
        'MISSING PAYMENT': {
            color: 'moccasin',
            description: 'Missing payment information on the basket/order.'
        },
        'CYBERSOURCE ERROR': {
            color: 'powderblue',
            description: 'Cybersource generated error on payment capture.'
        },
        'UNKNOWN': {
            color: 'sienna',
            description: 'Unkown error.'
        }
    }

    componentDidMount() {
        const { fetchOrderReportingFilesAsync } = this.props;

        fetchOrderReportingFilesAsync();
    }

    mapPropsToPdfLibraryData() {
        return this.props.orderReporting?.files && this.props.orderReporting?.files.length
         ? this.props.orderReporting.files.map(data => {
            return {
                uri: data.signedUrl,
                fileType: 'pdf'
            }
         })
         : [];
    }

    render() {
        return <AdminWrapper>
            <div>
                {
                    this.props.orderReporting?.files && this.props.orderReporting?.files.length
                        ? <DocViewer
                            prefetchMethod="GET"
                            documents={this.mapPropsToPdfLibraryData()}
                            pluginRenderers={DocViewerRenderers}
                        />
                        : null
                }
            </div>
            <div>
                <h3>Legend</h3>
                <div className='legend'>
                    {Object.keys(this.legend).map((legendElementKey, index) => {
                        let legendElement = this.legend[legendElementKey];

                        return <div key={index} className="legendElement">
                            <span key={index} className="legendErrorName" style={{backgroundColor: legendElement.color}}>{legendElementKey}</span>
                            <div className="colorBox"></div><span>{legendElement.description}</span>
                        </div>
                    })}
                </div>
            </div>
        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    orderReporting: state.orderReporting
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderReportingFilesAsync: () => dispatch(fetchOrderReportingFilesAsync())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(OrderErrorReporting);