import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AdminWrapper from './AdminControlPanel';
import StoreLink from '../../models/StoreLink';

import {
    fetchStoreLinksAsync,
    createStoreLinkAsync,
    removeStoreLinkAsync
} from '../../stateManagment/slices/iconsSlice';

class AdminLinkControl extends React.Component {
    URL_PATERN = '.*';
    LABEL_REGEX = '.*';
    NUMBER_PATERN = '.*';

    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();

        this.state = {
            newUrl: '',
            newImageLabel: '',
            priority: 0,
            validated: false,
            imageFile: ''
        }
    }

    uploadFileHandler = (event) => {
        this.setState({ imageFile: event.target.files[0] });
    };

    createNewIcon(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            validated: true
        });

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(this.state.imageFile);
        reader.onloadend = async () => {
            const base64Image = reader.result.split(',')[1];
            const fileName = this.state.imageFile.name;

            this.props.createStoreLinkAsync(new StoreLink(
                this.state.newUrl,
                this.state.newImageLabel,
                null,
                this.state.priority,
                base64Image,
                fileName
            ));
            
            this.setState({
                newUrl: '',
                newImageLabel: '',
                priority: 0,
                validated: false,
                imageContent: ''
            });

            this.fileInputRef.current.value = '';
        };
    }

    updateIcon(currentIconData, newPriorityValue) {
        if (currentIconData.priority !== newPriorityValue) {
            console.log(currentIconData)
            this.props.createStoreLinkAsync(new StoreLink(
                '',
                '',
                null,
                newPriorityValue,
                null,
                null,
                currentIconData.id
            ));
        }
    }

    loadData() {
        this.props.fetchStoreLinksAsync();
    }

    render() {
        return <AdminWrapper>
            Add/Remove URLs shown to the stores on the MAP page. <br></br>

            In the case you want to map the Store ID to a specific URL, just add {'{{storeID}}'} where the ID should be genereated in the URL. <br></br>
            Example: https://kicksusa.shopvisibl...?store=1&supName={'{{storeID}}'}
            <br></br>
            
            {

                <Button
                    variant="success"
                    onClick={() => this.loadData()}
                    disabled={this.props.icons?.isLoadingIconGet}
                >
                    {
                        !this.props.icons?.isLoadingIconGet
                            ? 'LOAD/RELOAD DATA'
                            : <Spinner animation="border" size="sm" />
                    }
                </Button>
            }
            <Form noValidate validated={this.state.validated} onSubmit={(event) => this.createNewIcon(event)}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th>URL</th>
                            <th>Label</th>
                            <th>Image</th>
                            <th>Priority</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {
                            <tr>
                                <td></td>
                                <td>
                                    <Form.Group controlId="URL">
                                        <Form.Control
                                            aria-label="Small"
                                            size="sm"
                                            value={this.state.newUrl}
                                            required
                                            pattern={this.URL_PATERN}
                                            onChange={e => {
                                                this.setState({
                                                    newUrl: e.target.value
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid url.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId="imageLabel">
                                        <Form.Control
                                            aria-label="Small"
                                            size="sm"
                                            required
                                            pattern={this.LABEL_REGEX}
                                            value={this.state.newImageLabel}
                                            onChange={e => {
                                                this.setState({
                                                    newImageLabel: e.target.value
                                                })
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Label.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId="image">
                                        <Form.Control
                                            type='file'
                                            id='image-file'
                                            label='Choose File'
                                            onChange={this.uploadFileHandler}
                                            ref={this.fileInputRef}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid image.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group controlId="priority">
                                        <Form.Control
                                            as="select"
                                            aria-label="Small"
                                            size="sm"
                                            value={this.state.priority}
                                            required
                                            onChange={e => {
                                                this.setState({
                                                    priority: e.target.value
                                                })
                                            }}
                                        >
                                            {
                                                Array.from(Array(25).keys()).map((value, index) => {
                                                    return (<option value={value} key={index}>{value}</option>)
                                                })
                                            }
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid priority number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Button
                                        variant="success"
                                        type="submit"
                                        disabled={this.props.icons?.isLoadingIconCreate}
                                    >
                                        {
                                            !this.props.icons?.isLoadingIconCreate
                                                ? 'ADD'
                                                : <Spinner animation="border" size="sm" />
                                        }
                                    </Button>
                                </td>
                            </tr>
                        }
                        {
                            this.props.icons?.icons && this.props.icons?.icons.length > 0
                                ? this.props.icons.icons.map((iconData, iconIndex) => {
                                    return  <tr key={iconIndex}>
                                        <td>{iconIndex + 1}</td>
                                        <td>{iconData.url || ''}</td>
                                        <td>{iconData.label || ''}</td>
                                        <td><img src={iconData.imageUrl || ''} alt={iconData.label || ''}></img></td>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                aria-label="Small"
                                                size="sm"
                                                value={iconData.priority}
                                                required
                                                onChange={e => {
                                                    this.updateIcon(iconData, e.target.value)
                                                }}
                                            >
                                                {
                                                    Array.from(Array(25).keys()).map((value, index) => {
                                                        return (<option value={value} key={index}>{value}</option>)
                                                    })
                                                }
                                            </Form.Control>
                                        </td>
                                        <td><Button
                                                variant="danger"
                                                onClick={() => this.props.removeStoreLinkAsync(iconData.id)}
                                                disabled={this.props.icons.isLoadingIconRemove}
                                            >
                                                {
                                                    !this.props.icons.isLoadingIconRemove
                                                        ? 'REMOVE'
                                                        : <Spinner animation="border" size="sm" />
                                                }
                                            </Button>
                                        </td>
                                    </tr>
                                })
                                : null
                        }
                    </tbody>
                </Table>
            </Form>

        </AdminWrapper>
    }
}

const mapStateToProps = (state) => ({
    icons: state.icons
});
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchStoreLinksAsync: (storeLink) => dispatch(fetchStoreLinksAsync(storeLink)),
        createStoreLinkAsync: (storeLink) => dispatch(createStoreLinkAsync(storeLink)),
        removeStoreLinkAsync: (storeLink) => dispatch(removeStoreLinkAsync(storeLink))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminLinkControl);