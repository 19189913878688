import React from 'react';
import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <Navbar expand="lg" variant="dark" className="bg-body-white" fixed="bottom" bg="snipes-footer">
        <Container>
            <Navbar.Brand href="#home">
                <img
                    src="/images/snipes-logo-light.png"
                    width="100"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
            </Navbar.Brand>
            <Navbar.Text onClick={() => localStorage.clear()} to="/terms" className="white-text" style={{cursor: 'pointer'}}>
                Clear Cache
            </Navbar.Text>
            <Navbar.Text to="/terms" className="white-text">
                © 1998 - 2024 SNIPES USA.
            </Navbar.Text>
        </Container>
    </Navbar>
  );
}

export default Footer;
