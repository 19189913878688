import { createSlice } from '@reduxjs/toolkit';
import { fetchUserAttributes } from 'aws-amplify/auth';

const SLICE_NAME = 'sfccStore';

export const sfccStoreSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        value: {},
        isLoading: false,
        error: null,
    },
    reducers: {
        setData: (state, action) => {
            state.value = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const fetchStoreDataAsync = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const registrationState = getState().registration;
    const currentState = getState().storeMessages;

    if (currentState.isLoading || !registrationState.registered) {
        return;
    }

    try {
        const data = await fetchUserAttributes();
        dispatch(setData(Object.assign(data, {
            storeID: data['custom:StoreID']
        })));
    } catch (error) {
        console.log('err', error)
        dispatch(setError(error.message));
    }
    dispatch(setLoading(false));
};

export const { setData, setLoading, setError } = sfccStoreSlice.actions;
export default sfccStoreSlice.reducer;
