import './Layout.css';
import Header from './Header'
import Footer from './Footer'
import SnipesPage from './SnipesPage'
import Messages from './Messages';
import Row from 'react-bootstrap/Row';

export default function Layout({ children }) {
    return (
        <SnipesPage>
            <Row className='pb-5 mb-5'>
                <Header/>
            </Row>
            <Row>
                <Messages></Messages>
                <main>{children}</main>
            </Row>
            <Row className='pt-5 mt-5'>
                <Footer/>
            </Row>
        </SnipesPage>
    )
};
