import { combineReducers } from '@reduxjs/toolkit';

import sfccStoreReducer from './slices/sfccStoreSlice';
import registrationReducer from './slices/registrationSlice';
import storeMessagesReducer from './slices/storeMessagesSlice';
import storeUsersReducer from './slices/storeUsersSlice';
import iconsSlice from './slices/iconsSlice';
import orderReportingSlice from './slices/orderReportingSlice';

const rootReducer = combineReducers({
    sfccStore: sfccStoreReducer,
    registration: registrationReducer,
    storeMessages: storeMessagesReducer,
    storeUsers: storeUsersReducer,
    icons: iconsSlice,
    orderReporting: orderReportingSlice
});

export default rootReducer;
